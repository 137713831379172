import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Definice biogeografie" />
    <h1>Definice biogeografie</h1>
    <p>Biogeografie je věda, kter&aacute; se zab&yacute;v&aacute; studiem roz&scaron;&iacute;řen&iacute; organismů v prostoru a čase. D&aacute; se ř&iacute;ci, že se biogeografie snaž&iacute; dokumentovat a&nbsp;pochopit prostorov&eacute; rozložen&iacute; biologick&eacute; rozmanitosti a&nbsp;změny tohoto rozložen&iacute; v čase.</p>
<p>Vět&scaron;inou jde o vědu popisnou a srovn&aacute;vac&iacute;, je jen m&aacute;lokdy experiment&aacute;ln&iacute;, a to už kvůli neproveditelnosti či etick&yacute;m probl&eacute;mům. Přesto k několika do&scaron;lo, př&iacute;kladem může b&yacute;t <a href="https://www.semanticscholar.org/paper/Experimental-Zoogeography-of-Islands%3A-The-of-Empty-Simberloff-Wilson/d2280bafab342fc75ea4837a43e98c2133e3a7c6" target="_blank" rel="noreferrer noopener">experiment z konce 60.&nbsp;let</a>, kdy byla sledov&aacute;na rekolonizace 6&nbsp;ostrovů v z&aacute;livu Floridy, na nichž byla předt&iacute;m populace odstraněna fumigac&iacute;.</p>
<p>Biogeografie využ&iacute;v&aacute; a syntetizuje poznatky z mnoha discipl&iacute;n (např.&nbsp;botanika, zoologie, <Link to="/ekologie-zivotni-prostredi/uvod/">ekologie</Link>, geografie aj.). Nej&uacute;žeji je v&scaron;ak spjata s těmito discipl&iacute;nami:</p>
<ul>
<li><strong>makroekologie</strong> (makro-data)</li>
<li><strong>fylogeografie</strong> (zejm&eacute;na genetick&eacute; anal&yacute;zy, souvisej&iacute;c&iacute; s historick&yacute;m &scaron;&iacute;řen&iacute;m druhů)</li>
<li><strong>paleoekologie</strong> (ekologie v historii, zejm&eacute;na na z&aacute;kladě star&yacute;ch pylů aj.)</li>
</ul>
<hr />
<h2>Kl&iacute;čov&eacute; pojmy biogeografie</h2>
<h3>Taxon</h3>
<p><strong>Taxon</strong> je jednotka hierarchick&eacute; klasifikace organizmů. Mezi <strong>z&aacute;kladn&iacute; druhy</strong> taxonů patř&iacute; v n&aacute;sleduj&iacute;c&iacute;m hierarchick&eacute;m pořad&iacute;:</p>
<ul>
<li><strong>dom&eacute;na </strong>(<em>imperium</em>)</li>
<li><strong>ř&iacute;&scaron;e </strong>(<em>regnum</em>)</li>
<li><strong>oddělen&iacute;/kmen</strong> (<em>divisio/phylum</em>)</li>
<li><strong>tř&iacute;da</strong> (<em>classis</em>)</li>
<li><strong>ř&aacute;d</strong> (<em>ordo</em>)</li>
<li><strong>čeleď</strong> (<em>familia</em>)</li>
<li><strong>rod</strong> (<em>genus</em>)</li>
<li><strong>druh</strong> (<em>species</em>)</li>
</ul>
<h3>Druh</h3>
<p>Nejběžněj&scaron;&iacute;m taxonem je <strong>druh</strong> (<em>species</em>). Druh definujeme jako skupinu populac&iacute;, kter&aacute; je reprodukčně izolovan&aacute; od ostatn&iacute;ch populac&iacute;. Druh m&aacute; binomick&eacute; (dvouslovn&eacute;) jm&eacute;no, složen&eacute; ze jm&eacute;na rodu a druhov&eacute;ho př&iacute;vlastku. Pokud použ&iacute;v&aacute;me latinsk&yacute; n&aacute;zev, p&iacute;&scaron;eme jej kurz&iacute;vou a svelk&yacute;m p&iacute;smenem na zač&aacute;tku (př. <em>Abies alba</em>).</p>
<p>V taxonomii se od hierarchick&eacute; pozice druhu odv&iacute;jej&iacute; tyto 2 typy taxonů:</p>
<ul>
<li><strong>supraspecifick&eacute; taxony</strong> (nad &uacute;rovn&iacute; druhu, např.&nbsp;ř&iacute;&scaron;e či rod)</li>
<li><strong>infraspecifick&eacute; taxony</strong> (pod &uacute;rovn&iacute; druhu, např.&nbsp;poddruh)</li>
</ul>
<h3>Populace</h3>
<p><strong>Populace </strong>je v ekologii a biogeografii definov&aacute;na jako soubor jedinců stejn&eacute;ho druhu. Tito jedinci se společně vyskytuj&iacute; na určit&eacute;m &uacute;zem&iacute;m a v určit&eacute;m čase.</p>
<h3>Společenstvo (biocen&oacute;za)</h3>
<p><strong>Společenstvo</strong> (nebo tak&eacute; <strong>biocen&oacute;za</strong>) je soubor populac&iacute; dvou nebo v&iacute;ce druhů. Tyto populace ob&yacute;vaj&iacute; vymezen&eacute; &uacute;zem&iacute; v určit&eacute;m časov&eacute;m &uacute;seku.</p>
<hr />
<ImgCon><Biogeo /><div>
<h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
<p>T&eacute;ma <strong>Definice biogeografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
<BtnWrap>
<Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
<Link to="/biogeografie/historie/"><button className="inv">Historie biogeografie &rarr;</button></Link>
</BtnWrap>
</div></ImgCon><hr />
  </Layout>
)
